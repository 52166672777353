<template>
  <b-modal
    id="ModalUpdateContactVendor"
    hide-footer
    no-close-on-backdrop
    hide-header-close
    scrollable
    size="lg"
  >
    <template #modal-header="">
      <div class="row w-100">
        <div class="col-10">
          <h5>
            {{ datainput.mode == "add" ? "Add Vendor" : "Edit Vendor" }}
          </h5>
        </div>
        <div class="col-2 text-right">
          <span type="button" @click="hide()">
            X
          </span>
        </div>
      </div>
    </template>
    <div class="row">
      <div class="col-12 col-lg-3 text-right mb-3">
        ชื่อร้านค้า <span class="text-danger"></span>
      </div>
      <div class="col-12 col-lg-9 mb-3" v-if="datainput.mode == 'edit'">
        <div class="input-group ">
          <input
            maxlength="500"
            type="text"
            class="form-control"
            disabled
            placeholder="*ชื่อร้านค้า"
            v-model="data.vendorName"
          />
        </div>
      </div>
      <div class="col-12 col-lg-9 mb-3" v-else>
        <cool-select
          v-model="data.vendorName"
          :items="items"
          placeholder="---โปรดเลือกร้านค้า---"
        />
      </div>
      <div class="col-12 col-lg-3 text-right mb-3">
        Email <span class="text-danger"></span>
      </div>
      <div class="col-12 col-lg-9 mb-3">
        <div class="input-group ">
          <input
            maxlength="1000"
            type="text"
            class="form-control"
            placeholder="*Email"
            v-model="data.vendorEmail"
          />
        </div>
        <p class=" m-0 p-0 font-0-7s"><span class=" font-600">Exp : </span><span class="text-danger ">exam1@hotmail.com,exam2@chg.co.th</span></p>
      </div>
      <div class="col-12 col-lg-3 text-right mb-3">
        เบอร์โทรศัพท์<span class="text-danger"></span>
      </div>
      <div class="col-12 col-lg-9 mb-3">
        <div class="input-group ">
          <input
            maxlength="100"
            type="text"
            class="form-control"
            placeholder="*เบอร์โทรศัพท์"
            v-model="data.vendorPhone"
          />
        </div>
      </div>
      <div class="col-12 col-lg-3 text-right mb-3">
        ชื่อผู้ติดต่อ<span class="text-danger"></span>
      </div>
      <div class="col-12 col-lg-9 mb-3">
        <div class="input-group ">
          <input
            maxlength="200"
            type="text"
            class="form-control"
            placeholder="*ชื่อผู้ติดต่อ"
            v-model="data.vendorContact"
          />
        </div>
      </div>
      <div class="col-12 col-lg-3 text-right mb-3">
        เว็บไซต์<span class="text-danger"></span>
      </div>
      <div class="col-12 col-lg-9 mb-3">
        <div class="input-group ">
          <input
            maxlength="500"
            type="text"
            class="form-control"
            placeholder="เว็บไซต์"
            v-model="data.vendorWeb"
          />
        </div>
      </div>
      <div class="col-12 col-lg-12 text-right  mb-3">
        <button type="button" class="btn bt-main" @click="save()">
          บันทึก
        </button>
        <button type="button" class="btn btn-default" @click="hide()">
          ยกเลิก
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { CoolSelect } from "vue-cool-select";
export default {
  name: "ModalUpdateContactVendor",
  components: {
    CoolSelect,
  },
  props: ["datainput"],
  data() {
    return {
      items: null,
      data: {
        mode: "",
        vendorId: "",
        vendorName: "",
        vendorEmail: "",
        vendorPhone: "",
        vendorContact: "",
        vendorWeb: "",
      },
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.getPimVendorList();
    if (this.datainput.mode == "edit") {
      this.setVendordata();
    }
  },
  methods: {
    validate() {
      if (
        this.data.vendorName == "" ||
        this.data.vendorName == null ||
        this.data.vendorName == "undefined"
      ) {
        throw `กรุณาเลือกร้านค้า`;
      } else if (
        this.data.vendorEmail == "" ||
        this.data.vendorEmail == null ||
        this.data.vendorEmail == "undefined"
      ) {
        throw `กรุณาระบุ Email`;
      } else if (
        this.data.vendorPhone == "" ||
        this.data.vendorPhone == null ||
        this.data.vendorPhone == "undefined"
      ) {
        throw `กรุณาระบุเบอร์โทรศัพท์`;
      } else if (
        this.data.vendorContact == "" ||
        this.data.vendorContact == null ||
        this.data.vendorContact == "undefined"
      ) {
        throw `กรุณาระบุชื่อผู้ติดต่อ`;
      }
    },
    hide() {
      this.$bvModal.hide("ModalUpdateContactVendor");
    },
    async getPimVendorList() {
      let getAPI = await this.$serviceAPI.call_API(
        "get",
        `vendor/getpimvendorlist`,
        {},
        1
      );
      var selected = [];
      for (var key in getAPI.data) {
        selected.push(getAPI.data[key].VendorName);
      }
      this.items = selected;
    },
    setVendordata() {
      const {
        vendorId,
        vendorName,
        vendorEmail,
        vendorPhone,
        vendorContact,
        vendorWeb,
      } = this.datainput.data;
      this.data = {
        vendorName: String(vendorId).trim() + " : " + String(vendorName).trim(),
        vendorEmail: String(vendorEmail).trim(),
        vendorPhone: String(vendorPhone).trim(),
        vendorContact: String(vendorContact).trim(),
        vendorWeb: String(vendorWeb).trim(),
      };
    },
    async save() {
      try {
        this.validate();
        var data = {
          mode: this.datainput.mode,
          vendorId: String(this.data.vendorName).split(" : ")[0],
          vendorName: String(this.data.vendorName).split(" : ")[1],
          vendorEmail: this.data.vendorEmail,
          vendorPhone: this.data.vendorPhone,
          vendorContact: this.data.vendorContact,
          vendorWeb: this.data.vendorWeb,
        };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `vendor/updatepimcontactvendor`,
          data,
          1
        );

        if (getAPI.data == "EXIST") {
          this.$serviceMain.showErrorAlert(
            this,
            `ขออภัยร้านค้า ${data.vendorName} มีอยู่แล้ว / Error`
          );
        } else if (getAPI.data == "OK") {
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        } else {
          this.$serviceMain.showErrorAlert(this, " บันทึกไม่สำเร็จ / Error");
        }
        this.$emit("getPimContactVendor");
        this.hide();
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style scoped>
.img {
  width: auto;
  height: 10vh;
}
</style>
